<div class="container center">
  <div class="ability">
    <h2>Многофункциональность</h2>
    <p>Осуществление в сметной программе сметных <br> расчётов по утвержденным методикам</p>
    <div class="calc">
      <img src="../../assets/images/ability/knife.png" width="486" height="425" alt="многофункциональность">
      <div>
        <div class="calc__info">
          <svg class="arrow top" width="41" height="44" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.4071 43.934H15.0208L0.674184 22.6271V21.9169H8.77077L22.4071 43.934ZM22.4071 0.326004L8.77077 22.3431H0.674184V21.6328L15.0208 0.326004H22.4071ZM40.589 43.934H33.2026L18.856 22.6271V21.9169H26.9526L40.589 43.934ZM40.589 0.326004L26.9526 22.3431H18.856V21.6328L33.2026 0.326004H40.589Z" fill="#5654DB"/>
          </svg>
          <p>
            При расчёте смет в программе <b>Сметный офис</b>
            используются актуальные методики для различных нормативных баз 
            (ТСН-2001, СН-2012 и&nbsp;т.д.) с возможностью собственных настроек расчета.
          </p>
          <svg class="arrow bottom" width="41" height="44" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.836293 43.9712L14.4727 21.9541H22.5692V22.6644L8.22266 43.9712H0.836293ZM0.836293 0.363235H8.22266L22.5692 21.6701V22.3803H14.4727L0.836293 0.363235ZM19.0181 43.9712L32.6545 21.9541H40.7511V22.6644L26.4045 43.9712H19.0181ZM19.0181 0.363235H26.4045L40.7511 21.6701V22.3803H32.6545L19.0181 0.363235Z" fill="#5654DB"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-primary center hr">
  <h2>Умеет все что нужно</h2>
</div>

<div class="container center">
  <div class="ability__cards">
    <div class="ability__card">
      <div class="ability__card-header"><img src="../../assets/images/ability/norm.png" width="219" height="200" alt="Нормативы и методики"></div>
      <div class="ability__card-body">Нормативы и методики</div>
      <div>Есть все необходимые нормативы и методическая информация для расчета смет.</div>
    </div>
    <div class="ability__card">
      <div class="ability__card-header"><img src="../../assets/images/ability/cloud.png" width="247" height="153" alt="Мобильность"></div>
      <div class="ability__card-body">Мобильность</div>
      <div>Доступ к программе из любого места, где есть интернет.</div>
    </div>
    <div class="ability__card">
      <div class="ability__card-header"><img src="../../assets/images/ability/lock.png" width="199" height="231" alt="Надежность"></div>
      <div class="ability__card-body">Надежность</div>
      <div>Надежное хранение данных на защищенных серверах.</div>
    </div>
  </div>
</div>

<div class="bg-primary center hr">
  <h2>Программа Сметный офис позволяет:</h2>
</div>

<div class="container">
  <ul>
    <li>составлять локальные, ресурсные ведомости, акты выполненных работ;</li>
    <li>производить сметные расчеты по согласно утвержденным методикам расчета сметной стоимости пересчета в текущие цены;</li>
    <li>использовать для составления сметной документации обширный массив нормативных данных: сборники сметных нормативов (расценки на работы, ценники на материальные ресурсы, ценники на эксплуатацию машин и механизмов), сборники индексов пересчета в текущие цены, сборники поправочных коэффициентов и нормативно-методическую литературу;</li>
    <li>осуществлять гибкий и разноплановый поиск по всему объему справочных данных;</li>
    <li>хранить, сортировать, корректировать и распечатывать сметную документацию;</li>
    <li>обмениваться сметной информацией с другими сметными программами с использованием универсального сметного формата АРПС 1.10 или формата XML.</li>
  </ul>
</div>