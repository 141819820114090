import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { UsageComponent } from './usage/usage.component';
import { AbilityComponent } from './ability/ability.component';
import { RegistrationComponent } from './registration/registration.component';
import { PricesComponent } from './prices/prices.component';
import { QuickStartComponent } from './quick-start/quick-start.component';
import { SupportComponent } from './support/support.component';
import { FooterComponent } from './footer/footer.component';
import { ScrolltotopComponent } from './scrolltotop/scrolltotop.component';

import { RegistrationService } from './registration/registration.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UsageComponent,
    AbilityComponent,
    RegistrationComponent,
    PricesComponent,
    QuickStartComponent,
    SupportComponent,
    FooterComponent,
    ScrolltotopComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSnackBarModule
  ],
  providers: [RegistrationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
