<div class="container">
  <div class="prices">
    <h2 class="center">Cтоимость программы</h2>
    <mat-tab-group class="group__nav">
      <mat-tab label="ТСН МГЭ"> 
        <div class="price_cards">
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с ТСН МГЭ</p>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 1 день</p>
              <p><b>База ТСН МГЭ </b></p>
              <p><b>Индексы ТСН МГЭ </b></p>
              <p><b>База ТСН МГЭ, Глава 13 </b></p>
              <p><b>Индексы  ТСН МГЭ, Глава 13 </b></p>
            </div>
            <div class="price_card-footer">
              <p><b>1 100 &#8381;</b> на 1 день</p>
            </div>
          </div>
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с ТСН МГЭ</p>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 30 дней</p>
              <p><b>База ТСН МГЭ </b></p>
              <p><b>Индексы ТСН МГЭ </b></p>
              <p><b>База ТСН МГЭ, Глава 13 </b></p>
              <p><b>Индексы  ТСН МГЭ, Глава 13 </b></p>
            </div>
            <div class="price_card-footer">
              <p><b>3 300 &#8381;</b> на 30 дней</p>
            </div>
          </div>
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с ТСН МГЭ</p>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 90 дней</p>
              <p><b>База ТСН МГЭ </b></p>
              <p><b>Индексы ТСН МГЭ </b></p>
              <p><b>База ТСН МГЭ, Глава 13 </b></p>
              <p><b>Индексы  ТСН МГЭ, Глава 13 </b></p>
            </div>
            <div class="price_card-footer">
              <p><b>9 900 &#8381;</b> на 90 дней</p>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="СН-2012">
        <div class="price_cards">
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с СН-2012*</p>
              <span>*(СН-2012 в текущих ценах на 01.10.2022)</span>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 1 день</p>
              <p><b>База СН-2012 </b><i>(в текущих ценах на 01.10.2022)</i></p>
            </div>
            <div class="price_card-footer">
              <p><b>1 000 &#8381;</b> на 1 день</p>
            </div>
          </div>
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с СН-2012*</p>
              <span>*(СН-2012 в текущих ценах на 01.10.2022)</span>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 30 дней</p>
              <p><b>База СН-2012 </b><i>(в текущих ценах на 01.10.2022)</i></p>
            </div>
            <div class="price_card-footer">
              <p><b>3 000 &#8381;</b> на 30 дней</p>
            </div>
          </div>
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с СН-2012*</p>
              <span>*(СН-2012 в текущих ценах на 01.10.2022)</span>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 90 дней</p>
              <p><b>База СН-2012 </b><i>(в текущих ценах на 01.10.2022)</i></p>
            </div>
            <div class="price_card-footer">
              <p><b>9 000 &#8381;</b> на 90 дней</p>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="ТСН МГЭ + СН-2012">
        <div class="price_cards">
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с ТСН МГЭ + СН-2012*</p>
              <span>*(СН-2012 в текущих ценах на 01.10.2022)</span>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 1 день</p>
              <p><b>База ТСН МГЭ </b></p>
              <p><b>Индексы ТСН МГЭ </b></p>
              <p><b>База ТСН МГЭ, Глава 13 </b></p>
              <p><b>Индексы  ТСН МГЭ, Глава 13 </b></p>
              <p><b>База СН-2012 </b><i>(в текущих ценах на 01.10.2022)</i></p>
            </div>
            <div class="price_card-footer">
              <p><b>1 600 &#8381;</b> на 1 день</p>
            </div>
          </div>
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с ТСН МГЭ + СН-2012*</p>
              <span>*(СН-2012 в текущих ценах на 01.10.2022)</span>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 30 дней</p>
              <p><b>База ТСН МГЭ </b></p>
              <p><b>Индексы ТСН МГЭ </b></p>
              <p><b>База ТСН МГЭ, Глава 13 </b></p>
              <p><b>Индексы  ТСН МГЭ, Глава 13 </b></p>
              <p><b>База СН-2012 </b><i>(в текущих ценах на 01.10.2022)</i></p>
            </div>
            <div class="price_card-footer">
              <p><b>4 800 &#8381;</b> на 30 дней</p>
            </div>
          </div>
          <div class="price_card">
            <div class="price_card-title">
              <p>Программа с ТСН МГЭ + СН-2012*</p>
              <span>*(СН-2012 в текущих ценах на 01.10.2022)</span>
            </div>
            <div class="price_card-body">
              <p><b>Программа СМР</b> — 90 дней</p>
              <p><b>База ТСН МГЭ </b></p>
              <p><b>Индексы ТСН МГЭ </b></p>
              <p><b>База ТСН МГЭ, Глава 13 </b></p>
              <p><b>Индексы  ТСН МГЭ, Глава 13 </b></p>
              <p><b>База СН-2012 </b><i>(в текущих ценах на 01.10.2022)</i></p>
            </div>
            <div class="price_card-footer">
              <p><b>14 400 &#8381;</b> на 90 дней</p>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
