<div class="registration">
  <div class="container center">
    <div *ngIf="!success">
      <h2 >Зарегистрироваться в программе Сметный офис</h2>
      <form [formGroup]="regForm" (ngSubmit)="submit()">
        <p>Пользоваться программой вы можете уже сейчас</p>
        <input [ngClass]="{'disabled':formLoading}" type="email" inputmode="email" formControlName="email" placeholder="Введите электронную почту" required>
        <button class="button" type="submit" *ngIf="!formLoading else loading">Зарегистрироваться</button>
        <ng-template #loading>
          <button class="button loading-button" type="submit">
            <div class="loading">
              <div></div><div></div><div></div>
            </div>
          </button>
        </ng-template>
      </form>
      <div class="registration__footer">
        <p>
          После нажатия на кнопку, мы предоставим вам лицензию к программе на 7 дней. <br>
          В составе лицензии: Программа СМР,  база ТСН МГЭ (41 дополнение), индексы ТСН МГЭ (2016 год июль). <br>
          Пароль к сервису мы отправим вам на почту. Без спама.
        </p>
        <span>Нажимая на кнопку «Зарегистрироваться» вы соглашаетесь на обработку персональных данных согласно «Политике безопасности»</span>
      </div>
    </div>
    <div class="success-message" *ngIf="success">
      <h2>Вы успешно зарегистрировались</h2>
      <p>Пароль для доступа в программу <br> отправлен на Вашу электронную почту</p>
      <a href="{{eolink}}" class="button">В сметный офис</a>
    </div>
  </div>
</div>
