import { Component } from '@angular/core';
import { appNavigation } from '../app.navigation';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  navigation = appNavigation;
  currentYear = new Date().getFullYear();
}
