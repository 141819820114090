import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize } from 'rxjs/operators';
import { RegistrationService } from './registration.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  
  regForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email, Validators.maxLength(256)]]
  });

  formLoading = false;
  success = false;
  eolink = '';

  constructor(private fb: FormBuilder, private registrationService: RegistrationService, private snackBar: MatSnackBar) {}

  submit() {
    if (!this.regForm.valid || this.formLoading) return;
    this.formLoading = true;

    this.registrationService.registration(this.regForm.value)
    .pipe(finalize(() => this.formLoading = false))
    .subscribe({
      next: (response: any) => {
        this.eolink = response.eolink,
        this.success = true
      },
      error: (err: any) => {
        const message = err.error?.details?.email[0] || 'Произошла непредвиденная ошибка. Попробуйте позже';
        this.snackBar.open(message, '', {
          panelClass: 'custom-snack-bar',
          duration: 5000
        });
      }
    });
  }
}
