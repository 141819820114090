<footer>
  <div class="container footer">
    <div class="footer__nav">
      <div><a class="nav-link" *ngFor="let item of navigation" [href]="'#'+item.src"><span>{{item.title}}</span></a></div>
      <span>&copy; {{currentYear}} Программа: «Сметный офис»</span>
    </div>
    <div class="footer__sup">
      <p>По вопросам поддержки</p>
      <b><a href="tel:+74952217859">+7 495 221-78-59</a></b>
      <p class="mail-link">Email: <a href="mailto:office@smeta.ru">office@smeta.ru</a></p>
    </div>
  </div>
</footer>
