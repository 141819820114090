<div class="container">
  <div class="support">
    <div class="support-po">
      <h2>Поддержка ПО</h2>
      <p>Мы всегда заботимся о качестве пользовательских руководств. Они насыщены полезной информацией и легки в понимании</p>
      <div>
        <img src="../../assets/images/support/comp.png" width="240" height="173" alt="Поддержка ПО">
        <div>
          <a href="../../assets/process.pdf" target="_blank"><span>Описание функциональных характеристик и описание процессов</span></a>
          <a href="../../assets/ref.pdf" target="_blank"><span>Справка</span></a>
          <a href="../../assets/install.pdf" target="_blank"><span>Инструкция по установке</span></a>
        </div>
      </div>
    </div>
    <div class="support-phone bg-primary">
      <h3>Телефоны</h3>
      <div>
        <p><a href="tel:+74952217859">+7 495 221-78-59</a></p>
        <p><a href="tel:+74959741589">+7 495 974-15-89</a></p>
        <i>По Москве</i>
      </div>
      <div>
        <p><a href="tel:88003337877">8 800 333-78-77</a></p>
        <i>По всей России</i>
        <div class="support-phone__img">
          <img src="../../assets/images/support/support.png" width="275" height="202" alt="Телефоны">
        </div>
      </div>
    </div>
  </div>
</div>
