import { Component } from '@angular/core';
import { appNavigation } from '../app.navigation';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  navigation = appNavigation;
}
