<header>
  <div class="container">
    <div class="header">
      <div class="header__right">
        <div class="header__logo">
          <svg width="86" height="58" viewBox="0 0 86 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.4594 1.55903C31.7155 1.55903 34.9052 2.11332 37.9401 3.20653C40.7717 4.22651 43.4066 5.69141 45.7769 7.56301L24.8544 28.4861L24.6482 28.6924L24.8544 28.8986L46.6973 50.7415C44.2551 52.8439 41.4998 54.4933 38.5027 55.6467C35.3004 56.8791 31.9214 57.504 28.4594 57.504C24.683 57.504 21.0197 56.7644 17.5714 55.3059C14.2405 53.8971 11.249 51.8802 8.68004 49.3112C6.11106 46.7422 4.09415 43.7507 2.68534 40.4199C1.22684 36.9715 0.487317 33.3083 0.487317 29.5319C0.487318 25.7554 1.22684 22.0921 2.68534 18.6437C4.09417 15.3128 6.11108 12.3212 8.68005 9.75214C11.2491 7.18303 14.2405 5.16604 17.5714 3.75717C21.0197 2.29859 24.683 1.55903 28.4594 1.55903Z" fill="white"/>
            <path d="M29.9984 28.6916L57.9785 56.6717L85.9586 28.6916L57.9785 0.711495L29.9984 28.6916Z" fill="white"/>
          </svg>     
          <span>Сметный офис</span>       
        </div>
        <h1>Безграничные облачные возможности сметных решений</h1>
        <div class="header__action"><a class="button" href="#usage">Подробнее</a></div>
      </div>
      <div class="header__left">
        <nav><a class="nav-link" *ngFor="let item of navigation" [href]="'#'+item.src"><span>{{item.title}}</span></a></nav>
        <img src="../../assets/images/header/header-comp.png" width="816" height="440" alt="ноутбук">
      </div>
    </div>
  </div>
</header>
