import { Component, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'scrolltotop',
  templateUrl: './scrolltotop.component.html',
  styleUrls: ['./scrolltotop.component.scss']
})
export class ScrolltotopComponent {

  public windowScrolled: boolean = false;

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    this.windowScrolled = event.target.documentElement.scrollTop > 500;
  }

  public scrollToTop(): void {
    this._document.documentElement.scrollTop = 0;
  }
}
