<div class="quick-start">
  <div class="container quick-start__wrap">
    <div>
      <h2>Быстрый старт <br> в работе!</h2>
      <p>Удобный интерфейс, быстрая работа и возможность работать везде — наши главные задачи <br> при создании Сметного офиса.</p>
      <a class="button" href="#registration">Зарегистрироваться</a>
    </div>
    <img src="../../assets/images/quick-start/rocket.png" width="427" height="393" alt="Быстрый старт в работе">
  </div>
</div>
