<div class="container center">
  <div class="usage">
    <h2>Простое использование</h2>
    <div class="usage__cards">
      <div class="usage__card">
        <div class="usage__card-header"><img src="../../assets/images/usage/usage-comp.png" width="259" height="170" alt="Простота в использовании"></div>
        <div class="usage__card-body">Простота в использовании</div>
        <div>На обучение работе в программе уходит 30 минут.</div>
      </div>
      <div class="usage__card">
        <div class="usage__card-header"><img src="../../assets/images/usage/search.png" width="228" height="216" alt="Удобный поиск по нормативам"></div>
        <div class="usage__card-body">Удобный поиск по&nbsp;нормативам</div>
        <div>Программа подскажет ключевые слова для поиска, найдет нужный норматив по одному слову.</div>
      </div>
      <div class="usage__card">
        <div class="usage__card-header"><img src="../../assets/images/usage/cog.png" width="243" height="197" alt="Автоматизация"></div>
        <div class="usage__card-body">Автоматизация</div>
        <div>Автоматический подбор поправок (поправочных коэффициентов).</div>
      </div>
    </div>
  </div>
</div>

<div class="usage-reg">
  <div class="container">
    <h2>Начать использовать Сметный офис уже сейчас</h2>
    <div class="usage-reg__action">
      <a class="button" href="#registration">Зарегистрироваться</a>
    </div>
  </div>
</div>